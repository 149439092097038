import { NgModule } from '@angular/core';
import { ShiftsUtils } from './shifts';
import { ExpressionsUtils } from './expressions';
import { CalendarUtils } from './calendar';

@NgModule({
  providers: [
    ShiftsUtils,
    ExpressionsUtils,
    CalendarUtils
  ]
})
export class UtilsModule { }
